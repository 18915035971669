import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const GatsbyImgaeFiexedHeight = ({
    filename,
    alt,
    height,
    ...props
}) => (
        <StaticQuery
            query={graphql`
        query($height: Int) {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                    fluid(maxHeight: $height) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}

        render={(data) => {
            const image = data.images.edges.find(n =>
                n.node.relativePath.includes(filename)
            )

            if (!image) {
                return null
            }

            return (
                <Img
                    alt={alt}
                    fluid={{ ...image.node.childImageSharp.fluid }}
                    {...props}
                    style={{ height: `${height}px` }}
                    imgStyle={{ objectFit: 'contain', height: `${height}px`, width: 'auto' }}
                />
            );
        }}
    />
);

export default GatsbyImgaeFiexedHeight;
